import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import styles from "./links.module.css"

const Card = ({ title, path, description, img, bigImg }) => {
  return (
    <a href={path}>
      <div className={styles.card}>
        {img && (
          <div
            className={[styles.imgContainer, bigImg && styles.big].join(" ")}
          >
            <Img fluid={img.childImageSharp.fluid} />
          </div>
        )}
        <h2>{title}</h2>
        {description && <p>{description}</p>}
      </div>
    </a>
  )
}

const LinksPage = ({ data }) => {
  return (
    <Layout bgColor="#EFF0F3" noFooter>
      <SEO title="Links" />
      <div className={styles.mainContainer}>
        <h1>Lucas Boh: links</h1>
        <p className={styles.link}>
          <a className="wave" href="mailto:contacto@lucasboh.com">
            contacto@lucasboh.com
          </a>
        </p>
        <div className={styles.cardsContainer}>
          <Card
            title="Estudio Obliquo"
            description="Diseño y desarrollo web"
            img={data.obliquo}
            path="https://estudioobliquo.com"
            bigImg
          />
          <Card
            title="Punto de Fuga Podcast"
            description="Conversaciones sobre las diversas realidades y problemáticas del Paraguay."
            img={data.podcast}
            path="http://puntodefugapodcast.com"
          />
          <Card
            title="Web Personal"
            img={data.profile}
            path="https://lucasboh.com"
          />
        </div>
      </div>
    </Layout>
  )
}

export default LinksPage

export const query = graphql`
  query LinksQuery {
    obliquo: file(relativePath: { eq: "obliquo.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    podcast: file(relativePath: { eq: "podcast.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    profile: file(relativePath: { eq: "profile.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
